<template>
  <div class="align-items-center">
    {{ modelValue }}
    <BaseIcon
      name="shield-x"
      v-if="!extraRow"
      class="text-danger"
      title="Not in stock"
    />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";

const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "NeedToSend",
  components: { BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Boolean,
    extraRow: String,
  },
  data() {
    return {};
  },
};
</script>
